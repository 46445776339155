












































































































































































































.page-wrapper-box {
  width: 100%;
  height: 100vh;
  position: relative;
}
.home-left {
  position: absolute;
  right: 2%;
  bottom: 6%;
  z-index: 11;
  float: right !important;
  display: inline-block;
  vertical-align: top;
  text-align: center;

  // &:nth-of-type(2) {
  //   animation: bounceIn 0.5s linear 0.4s forwards;
  // }
  // &:nth-of-type(3) {
  //   animation: bounceIn 0.5s linear 0.4s forwards;
  // }
  .left-item {
    width: 100%;
    height: 33%;
    padding-top: 0.1rem;

    &:nth-of-type(1) {
      .img-left-item {
        background-image: url("../../assets/new_ui/new_home_history.png");
        background-size: 100% 100%;
        &:hover {
          background-image: url("../../assets/new_ui/new_home_history_hover.png");
          background-size: 100% 100%;
        }
      }
    }
    &:nth-of-type(2) {
      .img-left-item {
        background-image: url("../../assets/new_ui/new_home_collection.png");
        background-size: 100% 100%;
        &:hover {
          background-image: url("../../assets/new_ui/new_home_collection_hover.png");
          background-size: 100% 100%;
        }
      }
    }
    &:nth-of-type(3) {
      .img-left-item {
        background-image: url("../../assets/new_ui/new_home_age_group.png");
        background-size: 100% 100%;
        &:hover {
          background-image: url("../../assets/new_ui/new_home_age_group_hover.png");
          background-size: 100% 100%;
        }
      }
    }
    .img-left-item {
      margin-top: 0.15rem;
      width: 100%;
      height: 100%;
      width: 0.78rem;
      height: 0.7rem;
      background-size: 100% 100%;

      // &:hover {
      //   // filter: opacity(0.8);
      //   filter: saturate(120%) brightness(90%);
      // }
    }
    .p-left-item {
      margin-top: 0.15rem;
      font-size: 0.2rem;
      color: #fff;
      line-height: 0.2rem;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
.home-page-wrapper {
  width: 14.36rem;
  height: 6.9rem;
  margin: auto;
  .home-right {
    // display: inline-block;
    vertical-align: top;

    width: calc(100%);
    height: 100%;

    text-align: center;
    // line-height: 600px;
    position: relative;

    top: 1.5rem;
    z-index: 10;

    .type-block {
      // overflow: hidden;

      display: inline-block;
      position: absolute;

      width: 3.32rem;
      height: 2.4rem;

      &.type-block-12156 {
        left: 0px;
        top: 0.49rem;
      }
      &.type-block-12158 {
        left: 1rem;
        top: 3.7rem;
      }
      &.type-block-12157 {
        right: 5.5rem;
        bottom: -0.15rem;
      }
      &.type-block-12155 {
        right: 1rem;
        top: 3.7rem;
      }
      &.type-block-12159 {
        right: 0px;
        top: 0.49rem;
      }
      .img-type-block {
        width: 100%;
        &:hover {
          animation: tada 0.5s linear forwards;
        }
        .img-type-block1 {
        }
      }
    }
    .tree_img {
      width: 5.12rem;
      height: 4.76rem;
      position: relative;
      // top: 9%;
    }
  }
}
.ageType {
  width: 0.65rem;
  height: 0.38rem;
  font-size: 0.15rem;
  position: absolute;
  bottom: 0.77rem;
  left: -0.43rem;
  img {
    width: 100%;
    height: 100%;
  }
}
