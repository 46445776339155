.page-wrapper-box {
  width: 100%;
  height: 100vh;
  position: relative;
}
.home-left {
  position: absolute;
  right: 2%;
  bottom: 6%;
  z-index: 11;
  float: right !important;
  display: inline-block;
  vertical-align: top;
  text-align: center;
}
.home-left .left-item {
  width: 100%;
  height: 33%;
  padding-top: 0.1rem;
}
.home-left .left-item:nth-of-type(1) .img-left-item {
  background-image: url("../../assets/new_ui/new_home_history.png");
  background-size: 100% 100%;
}
.home-left .left-item:nth-of-type(1) .img-left-item:hover {
  background-image: url("../../assets/new_ui/new_home_history_hover.png");
  background-size: 100% 100%;
}
.home-left .left-item:nth-of-type(2) .img-left-item {
  background-image: url("../../assets/new_ui/new_home_collection.png");
  background-size: 100% 100%;
}
.home-left .left-item:nth-of-type(2) .img-left-item:hover {
  background-image: url("../../assets/new_ui/new_home_collection_hover.png");
  background-size: 100% 100%;
}
.home-left .left-item:nth-of-type(3) .img-left-item {
  background-image: url("../../assets/new_ui/new_home_age_group.png");
  background-size: 100% 100%;
}
.home-left .left-item:nth-of-type(3) .img-left-item:hover {
  background-image: url("../../assets/new_ui/new_home_age_group_hover.png");
  background-size: 100% 100%;
}
.home-left .left-item .img-left-item {
  margin-top: 0.15rem;
  width: 100%;
  height: 100%;
  width: 0.78rem;
  height: 0.7rem;
  background-size: 100% 100%;
}
.home-left .left-item .p-left-item {
  margin-top: 0.15rem;
  font-size: 0.2rem;
  color: #fff;
  line-height: 0.2rem;
}
.home-left .left-item .p-left-item:hover {
  opacity: 0.8;
}
.home-page-wrapper {
  width: 14.36rem;
  height: 6.9rem;
  margin: auto;
}
.home-page-wrapper .home-right {
  vertical-align: top;
  width: calc(100%);
  height: 100%;
  text-align: center;
  position: relative;
  top: 1.5rem;
  z-index: 10;
}
.home-page-wrapper .home-right .type-block {
  display: inline-block;
  position: absolute;
  width: 3.32rem;
  height: 2.4rem;
}
.home-page-wrapper .home-right .type-block.type-block-12156 {
  left: 0px;
  top: 0.49rem;
}
.home-page-wrapper .home-right .type-block.type-block-12158 {
  left: 1rem;
  top: 3.7rem;
}
.home-page-wrapper .home-right .type-block.type-block-12157 {
  right: 5.5rem;
  bottom: -0.15rem;
}
.home-page-wrapper .home-right .type-block.type-block-12155 {
  right: 1rem;
  top: 3.7rem;
}
.home-page-wrapper .home-right .type-block.type-block-12159 {
  right: 0px;
  top: 0.49rem;
}
.home-page-wrapper .home-right .type-block .img-type-block {
  width: 100%;
}
.home-page-wrapper .home-right .type-block .img-type-block:hover {
  animation: tada 0.5s linear forwards;
}
.home-page-wrapper .home-right .tree_img {
  width: 5.12rem;
  height: 4.76rem;
  position: relative;
}
.ageType {
  width: 0.65rem;
  height: 0.38rem;
  font-size: 0.15rem;
  position: absolute;
  bottom: 0.77rem;
  left: -0.43rem;
}
.ageType img {
  width: 100%;
  height: 100%;
}
