





























































































































































































































































































































































































































































































































































































































.play-page-wrapper {
  width: 16.44rem;
  height: 7.61rem;
  margin: 0 auto;
  border-radius: 0.4rem;
  position: relative;

  .plye-page-box {
    width: 12rem;
    // width: calc(155.5vh);
    width: 100%;
    height: 100%;

    background: #ade787;
    border-radius: 0.4rem;
    position: absolute;
    // margin-top: 125px;
    margin-top: 0.9rem;
  }

  .play-box {
    width: 100%;
    height: 100%;
    background: #ade787;
    border-radius: 0.4rem;
    position: relative;
    .play-left-box {
      display: inline-block;
      vertical-align: top;
      // width: 77.8%;
      width: 13.12rem;
      height: 100%;

      background: rgba(0, 0, 0, 1);
      background: #fff;
      overflow: hidden;
      position: relative;
      border-radius: 0.4rem;
      padding: 0.24rem;
      // padding-top: 0.4rem;

      #videoBox {
        // width: 100%;
        // height: 100%;
      }
      .not-login-mask {
        width: 100%;
        height: 100%;
        backdrop-filter: blur(15px);
        background-color: rgba(4, 24, 4, 0.7);
        animation: self_fadeIn_1 0.2s linear forwards;
        position: relative;
        left: 0px;
        top: 0;
        z-index: 4;
        border-radius: 0.3rem;
        display: flex;
        img {
          border-radius: 0.3rem;
        }

        .not-login-box {
          display: flex;
          // width: 3.6rem;
          width: 6rem;
          // height: 230px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          text-align: center;
          opacity: 0;
          animation: self_fadeIn_1 0.3s linear forwards;

          .no_login_img {
            // width: 65%;
            // height: 70%;
            border-radius: 0px;
            // margin-right: 0.5rem;
            width: 100%;
          }

          p {
            color: #ffffff;
            font-family: Avenir, Helvetica, Arial, sans-serif;
          }
          .p1 {
            line-height: 0.25rem;
            height: 0.25rem;
            font-size: 0.18rem;
            font-weight: bold;
            margin-top: 0.4rem;
            margin-left: 0.3rem;
          }
          .p2 {
            margin-top: 0.15rem;
            line-height: 0.1rem;
            height: 0.22rem;
            font-size: 0.16rem;
            width: 3.3rem;
            margin-left: 0.3rem;
          }
          .p3 {
            margin: 0 auto;
            margin-top: 0.42rem;
            margin-top: 0.2rem;

            color: #fff;
            margin-left: 0.95rem;
          }
          .p4 {
            margin-top: 0.24rem;
            line-height: 0.22rem;
            height: 0.22rem;
            font-size: 0.16rem;
            color: #fff;
            margin-left: 0.3rem;
            span {
              color: #fcae2c;
              font-weight: 700;
              cursor: pointer;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
      .login-btn {
        width: 2rem;
        height: 0.46rem;
        line-height: 0.46rem;
        text-align: center;
        font-size: 0.16rem;
        border-radius: 0.1rem;
      }
      .not-login-img-cover {
        width: 12.6rem;
        height: 7.13rem;
        position: absolute;
        top: 0.25rem;
        border-radius: 0.36rem;
      }
    }
    .play-right-box {
      display: inline-block;
      vertical-align: top;
      width: 22.2%;
      width: 3.3rem;
      height: 100%;
      background: #ade787;
      position: relative;
      padding-bottom: 0.1rem;
      overflow: hidden;
      border-radius: 0.3rem;
      position: relative;
      z-index: 10;
      .top-title {
        width: 100%;
        // height: 60px;
        min-height: 0.76rem;
        line-height: 0.2rem;
        // padding-left: 8%;
        padding-left: 0.33rem;
        padding-right: 0.2rem;
        color: #696969;
        position: relative;
        overflow-x: auto;
        .packing-title {
          display: inline-block;

          // color: #00827e;
          color: #1c8816;
          font-size: 0.16rem;
          // font-size: 18px;
          // font-weight: bold;
        }
        .play-count {
          color: #1c8816;
          font-size: 0.16rem;

          .color-green {
            font-size: 0.18rem;
            color: #ff9346;
          }
        }
        .img-collection {
          width: 0.3rem;
          height: 0.3rem;
          position: absolute;
          top: 50%;
          margin-top: -0.13rem;
          right: 0.33rem;
          cursor: pointer;
          &:hover {
            filter: saturate(125%) brightness(108%);
          }
        }
      }
      @media (max-width: 1600px) {
        .packing-title {
          font-size: 16px;
        }
        .play-count {
          font-size: 16px;
          .color-green {
            font-size: 14px;
          }
        }
        .img-collection {
          right: 20px;
        }
      }
      .list-box {
        height: calc(100% - 0.6rem);
        overflow: hidden;
        position: relative;
        // padding-top: 10px;

        .arrow-container-prev {
          position: absolute;
          top: -0.7rem;
          left: 4%;
          width: 92%;
          height: 0.6rem;

          background: #ade787;
          box-shadow: 0px 0.19rem 0.18rem -0.18rem #022b0e;
          z-index: 3;
          transition: top 0.3s;
          &:hover {
            top: 0 !important;
          }
        }
        .arrow-container-next {
          position: absolute;
          // bottom: -70px;
          bottom: -0.7rem;
          left: 4%;
          width: 92%;
          height: 0.6rem;
          background: #ade787;
          box-shadow: 0 -0.19rem 0.18rem -0.18rem #022b0e;
          z-index: 3;
          transition: bottom 0.3s;
          &:hover {
            bottom: 0 !important;
          }
        }
        .img-arrow-top,
        .img-arrow-bottom {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          width: 0.4rem;
          &.img-arrow-top {
            bottom: 0.115rem;
          }
          &.img-arrow-bottom {
            // top: 10.5px;
            top: 0.105rem;
          }
        }
        .arrow-container {
          width: 100%;
          height: 100%;
          padding-top: 0.1rem;
          overflow: auto;
          .content-list {
            text-align: center;

            .resource-item-2 {
              display: inline-block;
              margin-bottom: 0.16rem;
              cursor: pointer;
              position: relative;
              .resource-item-2-inner {
                width: 100%;
                height: 100%;
                position: relative;
                background: rgba(255, 255, 255, 0.45);
                border-radius: 0.244rem;
                border: 0.08rem solid #d9fdc2;
              }
              .img-box {
                width: 100%;
                height: 100%;
                overflow: hidden;
                border-radius: 0.166rem;
                position: relative;
                .el-image__inner {
                  transition: all 0.5s;

                  &:hover {
                    transform: scale(1.1);
                  }
                }
                .el-image {
                  width: 100%;
                  height: 100%;
                  .image-slot {
                    width: 100%;
                    height: 100%;
                    .image-slot-placeholder {
                      font-size: 0px;
                      text-align: center;
                      display: inline-block;
                      vertical-align: top;
                      width: 100%;
                      height: 100%;
                    }
                    .el-icon-picture-outline {
                      font-size: 0px;
                      text-align: center;
                      display: inline-block;
                      vertical-align: top;
                      width: 100%;
                      height: 100%;
                      background-image: url("../../assets/pic-loading-failed.png");
                    }
                  }
                }
                .bottom-mask {
                  width: 100%;
                  height: 0.44rem;
                  background: linear-gradient(
                    180deg,
                    rgba(0, 0, 0, 0) 0%,
                    rgba(0, 0, 0, 0.65) 100%
                  );
                  position: absolute;
                  left: 0;
                  bottom: 0;
                  .resource-title {
                    display: inline-block;
                    width: calc(100% - 40px);
                    height: 0.2rem;
                    line-height: 0.2rem;
                    text-align: left;
                    font-size: 0.17rem;
                    // font-family: Avenir, Helvetica, Arial, sans-serif;
                    color: #ffffff;
                    position: absolute;
                    left: 0.14rem;
                    bottom: 0.12rem;
                  }
                }
              }

              .is-trailer {
                display: inline-block;
                width: 0.58rem;
                height: 0.5rem;
                line-height: 0.31rem;
                background-image: url("../../assets/new_ui/trailer.png");
                background-repeat: no-repeat;
                background-size: 100%;
                position: absolute;
                left: -0.13rem;
                top: 0.08rem;
                font-size: 0.14rem;
                // font-weight: bold;
                // font-family: Avenir, Helvetica, Arial, sans-serif;
                color: #ffffff;
              }

              .resource-duration {
                width: 0.54rem;
                // padding: 0 6px;
                border-radius: 0.1446rem;
                height: 0.24rem;
                line-height: 0.24rem;
                font-size: 0.12rem;
                // font-family: Avenir, Helvetica, Arial, sans-serif;
                color: #ffffff;
                background-color: rgba(0, 0, 0, 0.6);
                position: absolute;
                right: 0.12rem;
                top: 0.12rem;
              }
              &:not(.resource-item-selected):hover {
                .resource-item-2-inner {
                  // border-color: #FFE68B;
                }
              }
              &.resource-item-selected {
                .resource-item-2-inner {
                  border-color: #fff;
                  position: relative;
                  z-index: 2;
                }
                .select-bg {
                  width: calc(100% - 4px);
                  height: 80%;
                  background: #1a9928;
                  border-radius: 0.24rem;
                  z-index: 1;
                  position: absolute;
                  left: 0.02rem;
                  bottom: -0.08rem;
                }
              }
            }
          }
        }
      }
    }
  }

  .play_shade {
    width: 17.68rem;
    position: absolute;
    // bottom: -37px;
    // left: -61px;

    bottom: -0.37rem;
    left: -0.61rem;
  }

  .arrow-container::-webkit-scrollbar {
    display: block !important;
    // color: #00827e !important;
    // background-color: #86c35f !important;
  }

  // height: calc(100vh - 100px);
}

.arrow-container::-webkit-scrollbar-thumb {
  background-color: #86c35f !important;
}

// 16 : 10视频位置
.h16-10 {
  .play-page-wrapper .plye-page-box {
    margin-top: 1.6rem;
  }
}
